//Portfolio Images
import BeSocial from "../assets/projects/BeSocial.jpg";
import Khullabazzar from "../assets/projects/Khullabazzar.jpg";
import RentYourCar from "../assets/projects/RentYourCar.jpg";
import FeelTRX from "../assets/projects/Feeltrx.png";
import Testimonify from "../assets/projects/Testimonify.png";
import Command from "../assets/projects/Commandtest.png";
import StaffSync from "../assets/projects/StaffSync.png";
import DownTube from "../assets/projects/DownTube.jpg";

//Certifications Images
import BackendDevandAPIs_fcc from "../assets/certifications/BackendDevandAPIs_fcc.png";
import CSS_Sololearn from "../assets/certifications/CSS_Sololearn.jpg";
import DataVisualization_fcc from "../assets/certifications/DataVisualization_fcc.png";
import FrontendDevLibraries_fcc from "../assets/certifications/FrontendDevLibraries_fcc.png";
import HTML_Sololearn from "../assets/certifications/HTML_Sololearn.jpg";
import JSDSA_fcc from "../assets/certifications/JSDSA_fcc.png";
import ResWebDesign_fcc from "../assets/certifications/ResWebDesign_fcc.png";

//Resume File
import ResumeFile from "../assets/resume/resume.pdf";

//User Data
export const user = {
  id: 1,
  name: "Rojan Acharya",
  stack: "SAAS Enthusiast | Developer",
  description: `I am constantly evolving, embracing new skills and technologies. My passion for learning and exploring innovative ideas drives me to stay at the forefront of the industry. Whether it's mastering the latest frameworks, optimizing performance, or integrating cutting-edge tools, I am committed to continuous improvement. My adaptable nature and eagerness to expand my knowledge ensure that I am always equipped to tackle complex challenges and deliver exceptional results. I thrive in dynamic environments, where my love for technology can truly shine.`,
  support: "Everyday",
  avatar: "../assets/images/rojanaslogo.png",
  experience: "2 years",
};

//User Resume
export const resume = {
  resume_link: "https://files.fm/f/8j3us24jf4",
  resume_file: ResumeFile,
};

//Portfolio Data
export const projects = [
  {
    id: 1,
    project_title: "Windows Command Simulator",
    project_image: Command,
    project_desc: `<p><br /><span style="text-decoration: underline;">Simulate Windows Command Prompts</span></p>
<p><br /><em>Key functionalities:</em></p>
<p><em>- Real-time command execution</em></p>
<p><em>- Detailed explanations</em></p>
<p><em>- Command history</em></p>
<p><em>- Auto-complete</em></p>
<p><em>- Modern, animated user interface</em></p>
<p><em>- Multiple themes</em></p>

`,
    project_link: "https://commandtest.rojanacharya.com/",
  },
  {
    id: 2,
    project_title: "StaffSync - Simplify employee management with StaffSync",
    project_image: StaffSync,
    project_desc: `<p><br /><span style="text-decoration: underline;">Simplify employee management with StaffSync</span></p>
<p><br /><em>Key functionalities:</em></p>
<p><em>- Streamline your restaurant staff management with one platform</em></p>
<p><em>- Smart scheduling with an intuitive calendar interface</em></p>
<p><em>- Manage shift swaps and employee schedules with ease</em></p>
<p><em>- Team communication through a built-in encrypted messaging system</em></p>
<p><em>- Share updates and important information instantly</em></p>
<p><em>- Shift management and insights into labor costs</em></p>
<p><em>- Optimize staffing for better efficiency</em></p>

`,
    project_link: "https://restaurant-employee-management-system.vercel.app/",
  },
  {
    id: 3,
    project_title:
      "Testimonify - Revolutionize Your Customer Feedback withAI-powered testimonials",
    project_image: Testimonify,
    project_desc: `<p><br /><span style="text-decoration: underline;">Testimonify is a powerful testimonial and review platform with advanced analytics and AI integration.</span></p>
<p><br /><em>Key functionalities:</em></p>
<p><em>- Collect and display customer testimonials with a seamless UI</em></p>
<p><em>- Support for text, image, audio, and video reviews</em></p>
<p><em>- Advanced filtering and sorting options for testimonials</em></p>
<p><em>- AI-powered sentiment analysis for better insights</em></p>
<p><em>- Customizable testimonial cards with light/dark mode support</em></p>
<p><em>- Export testimonials as text files or PNG images</em></p>
<p><em>- Integration with social media platforms for easy sharing</em></p>
<p><em>- Secure authentication and role-based access control</em></p>
<p><em>- User-friendly dashboard with analytics and reports</em></p>
<p><em>- Pagination and infinite scroll support for better navigation</em></p>
<p><em>- Responsive design for optimal viewing on all devices</em></p>
<p><em>- SEO-friendly testimonial pages for better visibility</em></p>
<p><em>(Continuously improving to provide the best testimonial management experience)</em></p>
`,
    project_link: "https://www.testimonify.tech/",
  },
  {
    id: 4,
    project_title: "FeelTrx - Discover top-rated music based on your mood",
    project_image: FeelTRX,
    project_desc: `<p><br /><span style="text-decoration: underline;">FeelTrx is a platform for discovering top rated tracks based on your mood.</span></p>`,
    project_link: "https://feeltrx.com",
  },
  {
    id: 5,
    project_title: "BeScoial - Prove yourself a social animal (on progress)",
    project_image: BeSocial,
    project_desc: `<p><br /><span style="text-decoration: underline;">BeSocial is social media web application with a minimal UI and more features.</span></p>
    <p><br /><em>Key functionalities:</em></p>
    <p><em>- Favourite/Unfavourite functionality (as like/dislike on facebook)</em></p>
    <p><em>- Comment on post functionality</em></p>
    <p><em>- Saving a post</em></p>
    <p><em>- Protected routes for each users</em></p>
    <p><em>- Global friend/unfriend funtionality</em></p>
    <p><em>- Notifications system on favourite, comment on a post</em></p>
    <p><em>- Messaging functionality between users using socket.io</em></p>
    <p><em>- Nested comment functionality</em></p>
    <p><em>- CRUD Operations on protected profile</em></p>
    <p><em>- Profile update functionality</em></p>
    <p><em>- Global follow functionality</em></p>
    <p><em>- Protected follow, friend, posts, videos, photos</em></p>
    <p><em>- Easy status posting functionality with images/text/videos</em></p>
    <p><em>(Working for making it the best version of social media)</em></p>`,
    project_link: "https://linkedin.com/in/racharya404",
  },
  {
    id: 6,
    project_title: "Khullabazzar - A Place Of Awesome Products",
    project_image: Khullabazzar,
    project_desc: `<p><br /><span style="text-decoration: underline;">Khullabazzar is a multi vendor ecommerce site.</span></p>

      <p><br /><em>Key functionalities:</em></p>
      <p><em>- Multi-vendor functionality</em></p>
      <p><em>- Fully dynamic in nature</em></p>
      <p><em>- Developed robust server-side APIs using NodeJS</em></p>
      <p><em>- Made Protected routes for admin, seller and user with their specific dashboards</em></p>
      <p><em>- Developed a eye catching UI</em></p>
      <p><em>- Built a strong email authentication system</em></p>
      <p><em>- Built advanced search system</em></p>
      <p><em>- Built a category wise filter system of products</em></p>
      <p><em>- Built a workflow of voucher system in ecommerce sites</em></p>
      <p><em>- Built order preview and event section for the sellers</em></p>
      <p><em>- Built fully automated Cart and Wishlist system</em></p>
      <p><em>- Made a simple payment gateway with stripe</em></p>
      <p><em>Technologies used (MERN focused):</em></p>
      <p><em>MongoDB as a database, stripe for payment, react for UI, redux for state management, NodeJS and ExpressJS and Vercel for deployment</em></p>`,
    project_link: "https://khullabazzar.vercel.app/",
  },
  {
    id: 7,
    project_title: "RentYourCar - Rent your car in simple few clicks",
    project_image: RentYourCar,
    project_desc: `<p><span style="text-decoration: underline;">RentYourCar is car rental web application that has a simple UI to use and an integrated backend.</span></p>
    <p><br /><em>Key functionalities:</em></p>

    <p><em>-User authentication</em></p>
    <p><em>-Fully dynamic in nature</em></p>
    <p><em>-Built advanced search system</em></p>
    <p><em>-Protected routes for each users</em></p>
    <p><em>-Built fully automated car review and rental system</em></p>
    <p><em>-CRUD Operations on car rentals and additions</em></p>
    <p><em>-Strong filter system according to types, facilities, rating and price.</em></p>
    <p><em>-Strong search functionality</em></p>
    <p><em>-Simple payment gateway using stripe</em></p>
    <p><em>Technologies used (MERN focused):</em></p>
    <p><em>MongoDB as a database, stripe for payment, react for UI, redux for state management, NodeJS and ExpressJS and Vercel for deployment</em></p>`,
    project_link: "https://rentyourcar-rojan.vercel.app/",
  },
  {
    id: 8,
    project_title: "Downtube - YT Downloader",
    project_image: DownTube,
    project_desc: `<p><span style="text-decoration: underline;">Downtube is a webapp that allows user to download videos of youtube in mp3, mp4,flv and mov form.</span></p>
    <p><br /><em>Key functionalities:</em></p>

    <p><em>-Youtube API</em></p>
    <p><em>-Simple UI, easy to understand</em></p>
    <p><em>-Developed server-side APIs using NodeJS</em></p>
    <p><em>-Download functionality in formats like mp3, mp4,flv and mov</em></p>
    <p><em>Technologies used (MERN focused):</em></p>
    <p><em>MongoDB as a database, stripe for payment, react for UI, redux for state management, NodeJS and ExpressJS and Vercel for deployment</em></p>`,
    project_link: "https://yt-downloader-client.vercel.app/",
  },
];

//Certfications Data
export const certification = [
  {
    id: 1,
    certifications_title: "Backend Development and APIs",
    certifications_image: BackendDevandAPIs_fcc,
    certifications_desc: `<p><span>Learned:</span><br /><span>-Managing Packages with NPM</span><br /><span>-Basic Node and Express</span><br /><span>-MongoDB and Mongoose</span></p>
    <p><br /><span>Projects(freecodecamp.org):</span><br /><span>-Timestamp Microservice</span><br /><span>-Request Header Parser Microservice</span><br /><span>-URL Shortener Microservice</span><br /><span>-Exercise Tracker</span><br /><span>-File Metadata Microservice</span></p>`,
    certifications_link:
      "https://freecodecamp.org/certification/fcc938e916e-c451-416a-b547-d27cad819e1e/back-end-development-and-apis",
  },
  {
    id: 2,
    certifications_title: "CSS Fundamentals",
    certifications_image: CSS_Sololearn,
    certifications_desc: `<p><span>Learned:</span><br /><span>-CSS Fundamentals that drives whole CSS functionality</span></p>`,
    certifications_link:
      "https://www.linkedin.com/in/racharya404/details/certifications/1635548522149/single-media-viewer/?profileId=ACoAAEhOvg4BD1EXje5NkS08FpTU43Rm6VHrjVw",
  },
  {
    id: 3,
    certifications_title: "Front End Development Libraries",
    certifications_image: JSDSA_fcc,
    certifications_desc: `<p><span>Learned:</span><br /><span>-Bootstrap</span><br /><span>-jQuery</span><br /><span>-SASS</span><br /><span>-React</span><br /><span>-Redux</span><br /><span>-React and Redux</span></p>
    <p><br /><span>Projects(freecodecamp.org):</span><br /><span>-Random Quote Machine</span><br /><span>-Markdown Previewer</span><br /><span>-Drum Machine</span><br /><span>-JavaScript Calculator</span><br /><span>-25 + 5 Clock</span></p>`,
    certifications_link:
      "https://freecodecamp.org/certification/fcc938e916e-c451-416a-b547-d27cad819e1e/front-end-development-libraries",
  },
  {
    id: 4,
    certifications_title: "Responsive Web Design",
    certifications_image: ResWebDesign_fcc,
    certifications_desc: `<p><span>Learned:</span><br /><span>-HTML (Complex)</span><br /><span>-CSS (Complex)</span></p>
    <p><br /><span>Learned with Projects(freecodecamp.org):</span><br /><span>-HTML Forms by Building a Registration Form</span><br /><span>-CSS Box Model by Building a Rothko Painting</span><br /><span>-CSS Flexbox by Building a Photo Gallery</span><br /><span>-Typography by Building a Nutrition Label</span><br /><span>-Intermediate CSS by Building a Cat Painting</span><br /><span>-Responsive Web Design by Building a Piano</span><br /><span>-CSS Variables by Building a City Skyline</span><br /><span>-CSS Grid by Building a Magazine</span><br /><span>-CSS Animation by Building a Ferris Wheel</span><br /><span>-CSS Transforms by Building a Penguin</span></p>`,
    certifications_link:
      "https://freecodecamp.org/certification/fcc938e916e-c451-416a-b547-d27cad819e1e/responsive-web-design",
  },
  {
    id: 5,
    certifications_title: "JavaScript Algorithms and Data Structures",
    certifications_image: FrontendDevLibraries_fcc,
    certifications_desc: `<p><span>Learned:</span><br /><span>-JavaScript</span><br /><span>-ES6</span><br /><span>-Regular Expressions</span><br /><span>-Debugging</span><br /><span>-Data Structures (Basic)</span><br /><span>-Algorithm Scripting</span><br /><span>-OOP</span><br /><span>-Functional Programming</span></p>
    <p><br /><span>Projects(freecodecamp.org):</span><br /><span>-Palindrome Checker</span><br /><span>-Roman Numeral Converter</span><br /><span>-Caesars Cipher</span><br /><span>-Telephone Number Validator</span><br /><span>-Cash Register</span></p>`,
    certifications_link:
      "https://freecodecamp.org/certification/fcc938e916e-c451-416a-b547-d27cad819e1e/javascript-algorithms-and-data-structures",
  },
  {
    id: 6,
    certifications_title: "Data Visualization",
    certifications_image: DataVisualization_fcc,
    certifications_desc: `<p><span>Learned:</span><br /><span>-Data Visualization with D3</span><br /><span>-JSON APIs and AJAX</span></p>
    <p><br /><span>Projects(freecodecamp.org):</span><br /><span>-Visualize Data with a Bar Chart</span><br /><span>-Visualize Data with a Scatterplot Graph</span><br /><span>-Visualize Data with a Heat Map</span><br /><span>-Visualize Data with a Choropleth Map</span><br /><span>-Visualize Data with a Treemap Diagram</span></p>`,
    certifications_link:
      "https://freecodecamp.org/certification/fcc938e916e-c451-416a-b547-d27cad819e1e/data-visualization",
  },
  {
    id: 7,
    certifications_title: "HTML Fundamentals",
    certifications_image: HTML_Sololearn,
    certifications_desc: `<p><span>Learned:</span><br /><span>-HTML Fundamentals that drives most of the operational functionality of programs</span></p>`,
    certifications_link:
      "https://www.linkedin.com/in/racharya404/details/certifications/1635548520498/single-media-viewer/?profileId=ACoAAEhOvg4BD1EXje5NkS08FpTU43Rm6VHrjVw",
  },
];

//Skills Data
export const skill = [
  {
    id: 1,
    skill_name: "MERN Stack",
    level: "Intermediate",
  },
  {
    id: 2,
    skill_name: "NextJS",
    level: "Intermediate",
  },
  {
    id: 3,
    skill_name: "MySQL",
    level: "Intermediate",
  },
  {
    id: 4,
    skill_name: "React Native",
    level: "Beginner",
  },
  {
    id: 5,
    skill_name: "Typescript",
    level: "Intermediate",
  },
  {
    id: 6,
    skill_name: "AWS",
    level: "Beginner",
  },
  {
    id: 7,
    skill_name: "web3",
    level: "Beginner",
  },
  {
    id: 8,
    skill_name: "Java",
    level: "Beginner",
  },
  {
    id: 9,
    skill_name: "Spring Boot",
    level: "Beginner",
  },
  {
    id: 10,
    skill_name: "Python",
    level: "Beginner",
  },
  {
    id: 11,
    skill_name: "PostgreSQL",
    level: "Beginner",
  },
];
