import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { certification, projects, skill, user } from "../../static/data";

/**
 * SEO Component for managing all metadata across the application
 * Based on best practices for React applications
 */
export const SEO = ({
  title,
  description,
  image,
  article,
  canonicalUrl,
  noindex,
  keywords,
  imageType = "relative",
  projectData,
  schema,
  projectId,
  skillFilter,
  certificationId,
}) => {
  const location = useLocation();

  // Get project data if projectId is provided
  const projectDetails = projectId
    ? projects.find((p) => p.id === projectId)
    : null;

  // Get skills data if skill filter is provided
  const skillsData = skillFilter
    ? skill.filter((s) => skillFilter.includes(s.skill_name))
    : skill;

  // Get certification data if certificationId is provided
  const certificationDetails = certificationId
    ? certification.find((c) => c.id === certificationId)
    : null;

  // Default metadata configuration
  const defaultMetadata = {
    defaultTitle: `${user.name} | ${user.stack}`,
    titleTemplate: `%s | ${user.name} Portfolio`,
    defaultDescription: user.description,
    siteUrl: "https://rojanacharya.com",
    defaultImage: "/og-image.jpg",
    twitterUsername: "@rojan010",
    author: user.name,
    siteName: `${user.name} Portfolio`,
    locale: "en_US",
    type: "website",
  };

  // Construct SEO data
  const seo = {
    title: title || defaultMetadata.defaultTitle,
    description: description || defaultMetadata.defaultDescription,
    image:
      imageType === "absolute"
        ? image
        : `${defaultMetadata.siteUrl}${image || defaultMetadata.defaultImage}`,
    url: `${defaultMetadata.siteUrl}${location.pathname}`,
    keywords:
      keywords ||
      `${user.stack}, web development, full-stack developer, portfolio, ${
        user.name
      }, ${skill.map((s) => s.skill_name).join(", ")}`,
  };

  // Generate structured data for projects
  const generateStructuredData = () => {
    if (schema) return schema;

    if (projectDetails) {
      return {
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: projectDetails.project_title,
        description: projectDetails.project_desc
          .replace(/<[^>]*>?/gm, "")
          .substring(0, 160),
        image: projectDetails.project_image,
        url: projectDetails.project_link,
        author: {
          "@type": "Person",
          name: defaultMetadata.author,
        },
        creator: {
          "@type": "Person",
          name: defaultMetadata.author,
        },
        datePublished: new Date().toISOString(),
        keywords: seo.keywords,
      };
    }

    if (certificationDetails) {
      return {
        "@context": "https://schema.org",
        "@type": "EducationalCredential",
        name: certificationDetails.certifications_title,
        description: certificationDetails.certifications_desc
          .replace(/<[^>]*>?/gm, "")
          .substring(0, 160),
        url: certificationDetails.certifications_link,
        image: certificationDetails.certifications_image,
        educationalLevel: "Professional Development",
        credentialCategory: "Certification",
      };
    }

    if (skillsData.length > 0) {
      return {
        "@context": "https://schema.org",
        "@type": "ItemList",
        itemListElement: skillsData.map((skill, index) => ({
          "@type": "ListItem",
          position: index + 1,
          item: {
            "@type": "TechArticle",
            name: skill.skill_name,
            description: `${skill.skill_name} - ${skill.level} level`,
            proficiencyLevel: skill.level,
          },
        })),
      };
    }

    if (projectData) {
      const structuredData = {
        "@context": "https://schema.org",
        "@type": "Project",
        name: projectData.title,
        description: projectData.description || seo.description,
        image: seo.image,
        author: {
          "@type": "Person",
          name: defaultMetadata.author,
        },
        creator: {
          "@type": "Organization",
          name: defaultMetadata.siteName,
          url: defaultMetadata.siteUrl,
          logo: {
            "@type": "ImageObject",
            url: `${defaultMetadata.siteUrl}/logo.png`,
          },
        },
        datePublished: projectData.date || new Date().toISOString(),
        keywords: projectData.technologies?.join(", ") || seo.keywords,
      };

      return structuredData;
    }

    // Default portfolio schema
    return {
      "@context": "https://schema.org",
      "@type": "ProfilePage",
      mainEntity: {
        "@type": "Person",
        name: user.name,
        alternateName: user.stack,
        description: user.description,
        jobTitle: user.stack.split("|")[0].trim(),
        knowsAbout: skill.map((s) => s.skill_name),
        hasCredential: certification.map((c) => ({
          "@type": "EducationalCredential",
          name: c.certifications_title,
          url: c.certifications_link,
        })),
        workExample: projects.map((p) => ({
          "@type": "CreativeWork",
          name: p.project_title,
          url: p.project_link,
        })),
      },
    };
  };

  return (
    <Helmet title={seo.title} titleTemplate={defaultMetadata.titleTemplate}>
      {/* Basic Meta Tags */}
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {noindex && <meta name="robots" content="noindex" />}
      {seo.description && <meta name="description" content={seo.description} />}
      {seo.image && <meta name="image" content={seo.image} />}
      {<link rel="canonical" href={canonicalUrl ? canonicalUrl : seo.url} />}
      {seo.keywords && <meta name="keywords" content={seo.keywords} />}

      {/* Open Graph Meta Tags */}
      <meta property="og:site_name" content={defaultMetadata.siteName} />
      <meta property="og:locale" content={defaultMetadata.locale} />
      <meta
        property="og:type"
        content={article ? "article" : defaultMetadata.type}
      />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      {defaultMetadata.twitterUsername && (
        <meta
          name="twitter:creator"
          content={defaultMetadata.twitterUsername}
        />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}

      {/* Additional Meta Tags */}
      <meta name="author" content={defaultMetadata.author} />
      <meta name="theme-color" content="#EC0101" />

      {/* Experience and skills meta tags */}
      <meta name="experience" content={user.experience} />
      <meta
        name="skills"
        content={skill.map((s) => `${s.skill_name}:${s.level}`).join(", ")}
      />

      {/* Structured Data */}
      {generateStructuredData() && (
        <script type="application/ld+json">
          {JSON.stringify(generateStructuredData())}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
