import React, { useState, useEffect } from "react";
import {
  HeaderNav,
  NavContainer,
  NavItem,
  NavList,
  NavMenu,
  NavLogo,
  NavLink,
} from "./NavbarElements";

const Navbar = ({ darkMode, handleTermnialOpen }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [isTerminalClick, setIsTerminalClick] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 30) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const handleChange = () => {
    setIsTerminalClick(!isTerminalClick);
    handleTermnialOpen(!isTerminalClick);
  };

  return (
    <div className="z-0">
      <HeaderNav light={darkMode ? 1 : 0} id="header" scrollNav={scrollNav}>
        <NavContainer>
          <NavLogo to="home">rojan.dev</NavLogo>
          <NavLogo onClick={handleChange}>{"</terminal mode>"}</NavLogo>
          <NavMenu light={darkMode ? 1 : 0}>
            <NavList>
              <NavItem>
                <NavLink
                  to="home"
                  light={darkMode ? 1 : 0}
                  smooth={true}
                  duration={100}
                  spy={true}
                  offset={-30}
                >
                  <i className="bx bx-home"></i>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  to="about"
                  light={darkMode ? 1 : 0}
                  smooth={true}
                  duration={100}
                  spy={true}
                  offset={-30}
                >
                  <i className="bx bx-user"></i>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  to="skills"
                  light={darkMode ? 1 : 0}
                  smooth={true}
                  duration={100}
                  spy={true}
                  offset={-30}
                >
                  <i className="bx bx-code-alt"></i>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  to="works"
                  light={darkMode ? 1 : 0}
                  smooth={true}
                  duration={100}
                  spy={true}
                  offset={-30}
                >
                  <i className="bx bx-briefcase"></i>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="certification"
                  light={darkMode ? 1 : 0}
                  smooth={true}
                  duration={100}
                  spy={true}
                  offset={-80}
                >
                  <i className="bx bx-notepad"></i>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  to="contact"
                  light={darkMode ? 1 : 0}
                  smooth={true}
                  duration={100}
                  spy={true}
                  offset={-80}
                >
                  <i className="bx bx-user-voice"></i>
                </NavLink>
              </NavItem>
            </NavList>
          </NavMenu>

          {/* <ThemeIcon onClick={handleDarkMode} darkMode={darkMode}>
            <i
              className={!darkMode ? 'bx bx-moon' : 'bx bx-sun'}
              id="theme-button"
            ></i>
          </ThemeIcon> */}
        </NavContainer>
      </HeaderNav>
    </div>
  );
};

export default Navbar;
