import React from "react";
import { skill } from "../../static/data";
import SEO from "./SEO";

/**
 * Specialized SEO component for skills section
 * Allows filtering of skills or uses all skills if no filter provided
 */
const SkillsSEO = ({
  skillNames = [],
  sectionTitle = "Skills & Expertise",
}) => {
  // Filter skills if names are provided, otherwise use all
  const filteredSkills =
    skillNames.length > 0
      ? skill.filter((s) => skillNames.includes(s.skill_name))
      : skill;

  if (filteredSkills.length === 0) {
    console.warn("No skills found to display.");
    return null;
  }

  // Group skills by level
  const skillsByLevel = filteredSkills.reduce((acc, curr) => {
    if (!acc[curr.level]) {
      acc[curr.level] = [];
    }
    acc[curr.level].push(curr.skill_name);
    return acc;
  }, {});

  // Create description from skills grouped by level
  const description = Object.entries(skillsByLevel)
    .map(([level, skills]) => `${level} skills: ${skills.join(", ")}`)
    .join(". ");

  // Create keywords from all skill names and levels
  const keywords = [
    ...filteredSkills.map((s) => s.skill_name),
    ...Object.keys(skillsByLevel),
    "skills",
    "expertise",
    "technical skills",
    "development",
    "programming",
  ].join(", ");

  return (
    <SEO
      title={sectionTitle}
      description={description}
      keywords={keywords}
      skillFilter={filteredSkills.map((s) => s.skill_name)}
    />
  );
};

export default SkillsSEO;
