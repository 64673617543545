import React from "react";
import { projects } from "../../static/data";
import SEO from "./SEO";

/**
 * Specialized SEO component for project pages
 * Automatically pulls data from projects array based on project ID
 */
const ProjectSEO = ({ projectId }) => {
  // Find the project by ID
  const project = projects.find((p) => p.id === projectId);

  if (!project) {
    console.warn(`Project with ID ${projectId} not found.`);
    return null;
  }

  // Strip HTML tags from project description
  const cleanDescription = project.project_desc
    .replace(/<[^>]*>?/gm, "")
    .substring(0, 160);

  // Extract keywords from project title and description
  const titleKeywords = project.project_title
    .split(" ")
    .filter((word) => word.length > 3)
    .map((word) => word.toLowerCase());

  // Deduplicate keywords
  const keywords = [
    ...new Set([
      ...titleKeywords,
      "project",
      "portfolio",
      project.project_title.split("-")[0]?.trim().toLowerCase(),
    ]),
  ].join(", ");

  return (
    <SEO
      title={project.project_title}
      description={cleanDescription}
      image={project.project_image}
      imageType="absolute"
      keywords={keywords}
      projectId={projectId}
    />
  );
};

export default ProjectSEO;
