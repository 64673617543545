import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import About from "../components/Home/About/About";
import Certification from "../components/Home/Certification/Certification";
import Contact from "../components/Home/Contact/Contact";
import Footer from "../components/Home/Footer/Footer";
import HeroSection from "../components/Home/HeroSection/HeroSection";
import Navbar from "../components/Home/Navbar/Navbar";
import Projects from "../components/Home/Projects/Projects";
import Scrollup from "../components/Home/Scrollup/Scrollup";
import Skills from "../components/Home/Skills/Skills";
import Modal from "../components/modal";
import { SEO } from "../components/SEO";
import TopParent from "../layout/main";
import { certification, projects, skill, user } from "../static/data";
import TerminalContainer from "../terminal/TerminalContainer";
import CustomTrailCanvas from "../utils/custom-trail-canvas.jsx";

// Styled components with theme awareness
const Main = styled(motion.div)`
  background-color: ${(props) =>
    props.isDark ? "hsl(355, 100%, 7%)" : "hsl(219, 100%, 99%)"};
  position: relative;
  min-height: 100vh;
  overflow: ${(props) => (props.isTerminalOpen ? "hidden" : "auto")};
  transition: background-color 0.3s ease-in-out;
`;

// Animation variants
const pageVariants = {
  initial: { opacity: 0 },
  in: {
    opacity: 1,
    transition: { duration: 0.5, ease: "easeInOut" },
  },
  out: {
    opacity: 0,
    transition: { duration: 0.3, ease: "easeInOut" },
  },
};

const Home = () => {
  const [isTerminalOpen, setIsTerminalOpen] = useState(false);

  const isDark = false;

  useEffect(() => {
    // Update body overflow style based on terminal state
    document.body.style.overflow = isTerminalOpen ? "hidden" : "auto";
  }, [isTerminalOpen]);

  const handleTerminalButtonClick = (data) => {
    setIsTerminalOpen(data);
  };

  const handleCloseModal = () => {
    setIsTerminalOpen(false);
  };

  // Home-specific SEO data
  const homeSchema = {
    "@context": "https://schema.org",
    "@type": "ProfilePage",
    mainEntity: {
      "@type": "Person",
      name: user.name,
      alternateName: user.stack,
      description: user.description,
      image: "./assets/images/rojanaslogo.png",
      sameAs: [
        "https://github.com/rojanacharya",
        "https://linkedin.com/in/racharya404",
        "https://twitter.com/rojan010",
      ],
      jobTitle: user.stack.split("|")[0].trim(),
      worksFor: {
        "@type": "Organization",
        name: `${user.name} Portfolio`,
      },
      knowsAbout: skill.map((s) => s.skill_name),
      hasCredential: certification.map((c) => ({
        "@type": "EducationalCredential",
        name: c.certifications_title,
        url: c.certifications_link,
      })),
      workExample: projects.map((p) => ({
        "@type": "CreativeWork",
        name: p.project_title,
        url: p.project_link,
      })),
    },
  };

  return (
    <>
      {/* Page-specific SEO */}
      <SEO
        title={`${user.name} - ${user.stack}`}
        description={user.description}
        keywords={`${user.name}, portfolio, ${
          user.stack
        }, web developer, full-stack, projects, skills, contact, ${skill
          .map((s) => s.skill_name)
          .join(", ")}`}
        schema={homeSchema}
      />

      <div className="bg-background">
        <TopParent>
          <Navbar
            // handleDarkMode={toggleTheme}
            darkMode={false}
            handleTermnialOpen={handleTerminalButtonClick}
          />
          <Main
            isDark={isDark}
            isTerminalOpen={isTerminalOpen}
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
          >
            <HeroSection darkMode={isDark} />
            <About darkMode={isDark} />
            <Skills darkMode={isDark} />
            <Projects darkMode={isDark} />
            <Certification darkMode={isDark} />
            <Contact darkMode={isDark} />
            <Footer />
          </Main>
          <Scrollup darkMode={isDark} />
          {isTerminalOpen && (
            <Modal onClose={handleCloseModal}>
              <CustomTrailCanvas className="pointer-events-none fixed inset-0 h-full w-full" />
              <TerminalContainer
                handleTerminalChange={handleTerminalButtonClick}
              />
            </Modal>
          )}
        </TopParent>
      </div>
    </>
  );
};

export default Home;
