import React, { useState } from "react";
import { certification } from "../../../static/data";
import { SectionSubtitle, SectionTitle } from "../../SectionTitleElements";
import { CertificationSEO } from "../../SEO";
import {
  CertificationButton,
  CertificationCard,
  CertificationContainer,
  CertificationImg,
  CertificationSection,
  CertificationTitle,
} from "./CertificationElements";

const Certification = ({ darkMode }) => {
  const [open, setOpen] = useState(false);
  const [selectedCertification, setSelectedCertification] = useState(null);
  const [page, setPage] = useState(1);
  const itemsPerPage = 4;

  const handleOpenModal = (certification) => {
    setSelectedCertification(certification);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCertification(null);
    setOpen(false);
  };

  // Calculate pagination variables
  const totalPages = Math.ceil(certification.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const paginatedData = certification.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  // Handle pagination actions
  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
      <CertificationSection id="certification">
        <SectionSubtitle>Certifications</SectionSubtitle>
        <SectionTitle>Recent Certifications</SectionTitle>

        <CertificationContainer>
          {paginatedData.map((certificationItem) => (
            <CertificationCard
              light={darkMode ? 1 : 0}
              key={certificationItem.id}
              className={`${darkMode ? "dark" : ""}`}
            >
              <CertificationImg
                src={certificationItem.certifications_image}
                alt={`${certificationItem.certifications_title} certificate`}
              ></CertificationImg>
              <CertificationTitle light={darkMode ? 1 : 0}>
                {certificationItem.certifications_title}
              </CertificationTitle>
              <div className="flex mt-3">
                <CertificationButton
                  href={certificationItem.certifications_link}
                  target="_blank"
                  aria-label={`View ${certificationItem.certifications_title} credential`}
                >
                  View Credential
                </CertificationButton>
                <CertificationButton
                  className="btn_ghost"
                  onClick={() => handleOpenModal(certificationItem)}
                  aria-label={`View details of ${certificationItem.certifications_title}`}
                >
                  View details
                </CertificationButton>
              </div>
            </CertificationCard>
          ))}
        </CertificationContainer>

        {/* Pagination controls */}
        {totalPages > 1 && (
          <div className="flex items-center justify-center mt-3">
            <button
              className={`px-3 py-1 bg-gray-200 rounded-md ${
                page === 1
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-300"
              }`}
              onClick={handlePrevPage}
              disabled={page === 1}
              aria-label="Go to previous page"
            >
              Prev
            </button>
            <span className="mx-3">{`Page ${page} of ${totalPages}`}</span>
            <button
              className={`px-3 py-1 bg-gray-200 rounded-md ${
                page === totalPages
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-300"
              }`}
              onClick={handleNextPage}
              disabled={page === totalPages}
              aria-label="Go to next page"
            >
              Next
            </button>
          </div>
        )}
      </CertificationSection>

      {/* Modal for certification details */}
      {selectedCertification && (
        <>
          {/* Certification-specific SEO */}
          <CertificationSEO certificationId={selectedCertification.id} />

          <div
            className={`fixed z-50 inset-0 h-screen flex justify-center items-center transition-colors ${
              open ? "visible bg-black/80" : "invisible"
            }`}
            onClick={handleCloseModal}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className={`max-h-[600px] w-full mx-3 md:mx-6 bg-white rounded-xl overflow-y-auto shadow p-6 transition-all ${
                open ? "scale-100 opacity-100" : "scale-125 opacity-0"
              } dark:bg-[#281F1FFF]`}
            >
              <button
                onClick={handleCloseModal}
                className="fixed top-1 right-2 p-1.5 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600 dark:bg-[#281F1FFF]"
                aria-label="Close certification details"
              >
                <i className="bx bx-x text-2xl"></i>
              </button>

              <div className="w-full h-full my-8">
                <div className="flex flex-col md:gap-4 md:flex-row">
                  <img
                    src={selectedCertification.certifications_image}
                    alt={`${selectedCertification.certifications_title} certificate`}
                    className="w-full md:w-1/2 lg:w-[500px] object-cover rounded-xl shadow-xl"
                  />
                  <div className="mt-3">
                    <h3 className="text-xl md:text-[32px] text-gray-800 my-3 dark:text-gray-300">
                      {selectedCertification.certifications_title}
                    </h3>
                    <div className="mt-3 md:mt-4">
                      <CertificationButton
                        href={selectedCertification.certifications_link}
                        target="_blank"
                        aria-label={`View ${selectedCertification.certifications_title} credential online`}
                      >
                        View Credential
                      </CertificationButton>
                    </div>
                  </div>
                </div>
                <div
                  className="text-gray-800 break-words text-sm pt-6 dark:text-gray-200"
                  dangerouslySetInnerHTML={{
                    __html: selectedCertification.certifications_desc,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Certification;
