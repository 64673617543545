import React from "react";
import { certification } from "../../static/data";
import SEO from "./SEO";

/**
 * Specialized SEO component for certification pages
 * Automatically pulls data from certification array based on certification ID
 */
const CertificationSEO = ({ certificationId }) => {
  // Find the certification by ID
  const cert = certification.find((c) => c.id === certificationId);

  if (!cert) {
    console.warn(`Certification with ID ${certificationId} not found.`);
    return null;
  }

  // Strip HTML tags from certification description
  const cleanDescription = cert.certifications_desc
    .replace(/<[^>]*>?/gm, "")
    .substring(0, 160);

  // Extract keywords from certification title and description
  const titleKeywords = cert.certifications_title
    .split(" ")
    .filter((word) => word.length > 3)
    .map((word) => word.toLowerCase());

  // Deduplicate keywords
  const keywords = [
    ...new Set([
      ...titleKeywords,
      "certification",
      "skills",
      "learning",
      "professional development",
      "education",
    ]),
  ].join(", ");

  return (
    <SEO
      title={cert.certifications_title}
      description={cleanDescription}
      image={cert.certifications_image}
      imageType="absolute"
      keywords={keywords}
      certificationId={certificationId}
    />
  );
};

export default CertificationSEO;
