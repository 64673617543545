import React from "react";
import { Link } from "react-router-dom";
import SEO from "../../SEO/SEO";
import { motion } from "framer-motion";

const NotFound = () => {
  const isDark = true;

  // Animation variants
  const containerVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: { duration: 0.5 },
    },
  };

  const buttonVariants = {
    initial: { scale: 1 },
    hover: {
      scale: 1.05,
      transition: { duration: 0.2 },
    },
    tap: { scale: 0.95 },
  };

  return (
    <>
      <SEO
        title="Page Not Found"
        description="The page you are looking for might have been removed or is temporarily unavailable."
        noindex={true}
      />
      <motion.section
        className={`flex items-center h-screen p-8 md:p-16 ${
          isDark
            ? "text-gray-100 bg-kali-gray-dark"
            : "text-gray-800 bg-gray-50"
        }`}
        initial="initial"
        animate="animate"
        variants={containerVariants}
      >
        <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
          <div className="max-w-md text-center">
            <h1 className="mb-8 font-extrabold text-8xl md:text-9xl">
              <span className="sr-only">Error</span>
              <span className={`${isDark ? "text-kali-red" : "text-gray-600"}`}>
                404
              </span>
            </h1>
            <p
              className={`mb-8 text-xl md:text-2xl font-semibold ${
                isDark ? "text-kali-cyan" : "text-red-500"
              }`}
            >
              Sorry, we couldn't find this page.
            </p>
            <motion.div
              initial="initial"
              whileHover="hover"
              whileTap="tap"
              variants={buttonVariants}
            >
              <Link
                to="/"
                className={`
                  px-8 py-3 font-semibold rounded transition-all duration-300
                  ${
                    isDark
                      ? "bg-kali-red hover:bg-kali-red-dark text-white"
                      : "bg-red-600 hover:bg-red-700 text-white"
                  }
                  focus:outline-none focus:ring-2 focus:ring-offset-2
                  ${isDark ? "focus:ring-kali-red" : "focus:ring-red-500"}
                `}
                aria-label="Return to homepage"
              >
                Back to Home
              </Link>
            </motion.div>
          </div>
        </div>
      </motion.section>
    </>
  );
};

export default NotFound;
