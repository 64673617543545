import React from "react";
import { skill } from "../../../static/data";
import { SectionSubtitle, SectionTitle } from "../../SectionTitleElements";
import { SkillsSEO } from "../../SEO";
import {
  SkillBox,
  SkillContainer,
  SkillContent,
  SkillData,
  SkillGroup,
  SkillLevel,
  SkillName,
  SkillSection,
} from "./SkillsElements";

const Skills = ({ darkMode }) => {
  return (
    <>
      {/* Skills-specific SEO */}
      <SkillsSEO sectionTitle="My Technical Skills & Expertise" />

      <SkillSection id="skills">
        <SectionSubtitle>Technologies I use</SectionSubtitle>
        <SectionTitle>My Skills</SectionTitle>

        <SkillContainer>
          <SkillContent light={darkMode ? 1 : 0}>
            <SkillBox>
              <SkillGroup>
                {skill.map((skill) => (
                  <SkillData light={darkMode ? 1 : 0} key={skill.id}>
                    <i className="bx bxs-badge-check" aria-hidden="true"></i>
                    <div>
                      <SkillName light={darkMode ? 1 : 0}>
                        {skill.skill_name}
                      </SkillName>
                      <SkillLevel>{skill.level}</SkillLevel>
                    </div>
                  </SkillData>
                ))}
              </SkillGroup>
            </SkillBox>
          </SkillContent>
        </SkillContainer>
      </SkillSection>
    </>
  );
};

export default Skills;
