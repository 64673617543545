import React, { useState } from "react";
import { projects } from "../../../static/data";
import { SectionSubtitle, SectionTitle } from "../../SectionTitleElements";
import { ProjectSEO } from "../../SEO";
import {
  WorkButton,
  WorkCard,
  WorkContainer,
  WorkImg,
  WorkSection,
  WorkTitle,
} from "./ProjectElements";

const Work = ({ darkMode }) => {
  const [open, setOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [page, setPage] = useState(1);
  const itemsPerPage = 4;

  const handleOpenModal = (project) => {
    setSelectedProject(project);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedProject(null);
    setOpen(false);
  };

  // Calculate pagination variables
  const totalPages = Math.ceil(projects.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const paginatedData = projects.slice(startIndex, startIndex + itemsPerPage);

  // Handle pagination actions
  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
      <WorkSection id="works">
        <SectionSubtitle>Portfolio</SectionSubtitle>
        <SectionTitle>Recent Works and Projects</SectionTitle>

        {/* Conditional rendering for empty projects */}
        {projects.length === 0 && (
          <h4 className="p-3 text-sm text-red-700 font-medium">
            You have not set any projects.
          </h4>
        )}

        <WorkContainer>
          {paginatedData.map((project) => (
            <WorkCard
              light={darkMode ? 1 : 0}
              key={project.id}
              className={`${darkMode ? "dark" : ""}`}
            >
              <WorkImg
                src={project.project_image}
                alt="project_image"
              ></WorkImg>
              <WorkTitle light={darkMode ? 1 : 0}>
                {project.project_title}
              </WorkTitle>

              <div className="flex mt-3">
                <WorkButton href={project.project_link} target="_blank">
                  Demo
                </WorkButton>
                <WorkButton
                  className="btn_ghost"
                  onClick={() => handleOpenModal(project)}
                >
                  More details
                </WorkButton>
              </div>
            </WorkCard>
          ))}
        </WorkContainer>

        {/* Pagination controls */}
        {totalPages > 1 && (
          <div className="flex items-center justify-center mt-3">
            <button
              className={`px-3 py-1 bg-gray-200 rounded-md ${
                page === 1
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-300"
              }`}
              onClick={handlePrevPage}
              disabled={page === 1}
            >
              Prev
            </button>
            <span className="mx-3">{`Page ${page} of ${totalPages}`}</span>
            <button
              className={`px-3 py-1 bg-gray-200 rounded-md ${
                page === totalPages
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-300"
              }`}
              onClick={handleNextPage}
              disabled={page === totalPages}
            >
              Next
            </button>
          </div>
        )}
      </WorkSection>

      {/* Modal for project details with project-specific SEO */}
      {selectedProject && (
        <>
          {/* Project-specific SEO */}
          <ProjectSEO projectId={selectedProject.id} />

          <div
            className={`fixed z-50 inset-0 h-screen flex justify-center items-center transition-colors ${
              open ? "visible bg-black/80" : "invisible"
            }`}
            onClick={handleCloseModal}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className={`max-h-[600px] w-full mx-3 md:mx-6 bg-white rounded-xl overflow-y-auto shadow p-6 transition-all ${
                open ? "scale-100 opacity-100" : "scale-125 opacity-0"
              } dark:bg-[#281F1FFF]`}
            >
              <button
                onClick={handleCloseModal}
                className="fixed top-1 right-2 p-1.5 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600 dark:bg-[#281F1FFF]"
                aria-label="Close project details"
              >
                <i className="bx bx-x text-2xl"></i>
              </button>

              <div className="w-full h-full my-8">
                <div className="flex flex-col md:gap-4 md:flex-row">
                  <img
                    src={selectedProject.project_image}
                    alt={`${selectedProject.project_title} preview`}
                    className="w-full md:w-1/2 lg:w-[500px] object-cover rounded-xl shadow-xl"
                  />
                  <div className="mt-3">
                    <h3 className="text-xl md:text-[32px] text-gray-800 my-3 dark:text-gray-300">
                      {selectedProject.project_title}
                    </h3>
                    <div className="mt-3 md:mt-4">
                      <WorkButton
                        href={selectedProject.project_link}
                        target="_blank"
                        aria-label={`Visit ${selectedProject.project_title} demo`}
                      >
                        Project Demo
                      </WorkButton>
                    </div>
                  </div>
                </div>
                <div
                  className="text-gray-800 break-words text-sm pt-6 dark:text-gray-200"
                  dangerouslySetInnerHTML={{
                    __html: selectedProject.project_desc,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Work;
