import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import SEO from "./components/SEO/SEO";
import NotFound from "./components/utils/notFound/NotFound";
import CursorProvider from "./CursorProvider";
import Home from "./pages/Home";

function App() {
  // Global structured data for the website
  const websiteSchema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "Rojan Acharya Portfolio",
    url: "https://rojanacharya.com",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://rojanacharya.com/search?q={search_term_string}",
      "query-input": "required name=search_term_string",
    },
    creator: {
      "@type": "Person",
      name: "Rojan Acharya",
      sameAs: [
        "https://twitter.com/rojan010",
        "https://github.com/rojanacharya",
        "https://linkedin.com/in/rojanacharya",
      ],
    },
  };

  return (
    <CursorProvider>
      <BrowserRouter>
        {/* Global SEO settings */}
        <SEO
          title="Rojan Acharya Portfolio"
          description="Full-stack developer specializing in SAAS applications, web development, and innovative solutions"
          schema={websiteSchema}
          keywords="SAAS, web development, full-stack, developer, portfolio, Rojan Acharya, React, Node.js, JavaScript"
        />

        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
        </Routes>

        <ToastContainer
          position="top-center"
          autoClose={1000}
          theme="colored"
          draggable
          pauseOnHover
        />
      </BrowserRouter>
    </CursorProvider>
  );
}

export default App;
